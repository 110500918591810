<template>
  <main>
    <div class="error">{{ errorPrivacy }}</div>
    <div class="page-content">
      <h3>Privacy Policy</h3>
      <div v-if="errorPrivacy">{{ errorPrivacy }}</div>
      <div v-for="privacyPolicy in privacyPolicies" :key="privacyPolicy.id">
        <p class="story-text" v-html="privacyPolicy.details"></p>
      </div>
    </div>
  </main>
  <Footer v-if="privacyPolicies" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import getCollectionStatic from "@/composables/getCollectionStatic";

export default {
  name: "Privacy",
  components: { Footer },
  setup() {
    const {
      error: errorPrivacy,
      documents: privacyPolicies
    } = getCollectionStatic("rhPrivacy", null, ["createdAt", "desc"], 1);

    return { errorPrivacy, privacyPolicies };
  }
};
</script>

<style>
</style>